
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.delegation-detail {
  background-color: $color-white;
  border-radius: 16px;
  padding: 32px;

  @include for-size(phone-landscape-down) {
    padding: 24px;
  }

  @include for-size(phone-portrait-down) {
    padding: 20px;
    width: 100vw;
    margin-left: -20px;
  }

  &__title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 17px;

    @include for-size(phone-landscape-down) {
      font-size: 20px;
    }

    @include for-size(phone-portrait-down) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;

    @include for-size(phone-landscape-down) {
      flex-wrap: wrap;
    }

    &:nth-of-type(1) {
      padding-bottom: 24px;
      border-bottom: 1px solid $color-grayscale-05;

      @include for-size(phone-portrait-down) {
        padding-bottom: 16px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @include for-size(phone-portrait-down) {
        margin-bottom: 16px;
      }
    }
  }

  &__item {

    &:not(:last-child) {
      margin-right: 32px;

      @include for-size(phone-landscape-down) {
        margin-right: 14px;
      }

      @include for-size(phone-portrait-down) {
        margin-right: 0;
      }
    }

    &--wide {
      width: 440px;

      @include for-size(phone-portrait-down) {
        width: 100%;
      }
    }

    &--dates {
      @include for-size(phone-landscape-down) {
        width: 100%;
        margin-top: 16px;
      }
    }

    &--align-end {
      align-self: flex-end;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;

    @include for-size(phone-portrait-down) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
    }

    &--empty {
      color: $color-black-op-25;
    }
  }

  &__dates {
    display: flex;

    @include for-size(phone-portrait-down) {
      justify-content: space-between;

      span {
        display: block;
        width: calc(100% - 10px);
      }
    }
  }

  &__date {

    &:first-child {
      margin-right: 16px;

      @include for-size(phone-portrait-down) {
        margin-right: 0;
      }
    }

    &:last-child {
      @include for-size(phone-portrait-down) {
        .esmp-button-wrapper {
          margin-left: auto;
        }
      }
    }

    @include for-size(phone-portrait-down) {
      width: calc(100% - 10px);

      .esmp-input-icon--button {
        display: none;
      }

      .esmp-input-icon--clear-button {
        right: 12px;
      }

      .esmp-input-element {
        font-size: 12px;
        padding-right: 30px;
      }
    }

    &--error {
      .esmp-input-label {
        color: $esmp-input-error-color;
      }
    }
  }

  &__add {
    @include for-size(phone-landscape-down) {
      padding: 0;
    }
    @include for-size(phone-portrait-down) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__user-contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__user-name {
    margin-right: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    transition: color $base-animation-time;
  }

  &__user-email {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-50;
    margin-right: 16px;
  }

  &__user-appointment {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-40;
  }

  .esmp-switch__label {
    font-size: 16px;
    line-height: 24px;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__rule {
    width: 100%;
    padding: 22px 24px;
    border-radius: 12px;
    border: 1px solid $color-black-op-10;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-size(phone-portrait-down) {
      padding: 12px 14px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__error {
    color: $esmp-input-error-color;
    word-break: break-word;
    display: inline-block;
    padding: 0 $base-gutter;
    margin-top: $base-gutter / 1.5;
    margin-bottom: $base-gutter / 1.5;
  }
}
